
.menu-item{
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
}

.menu-item:hover{
    color: #ED1C24 !important;
}

.active-menu-item {
    color: #ED1C24;
    border-bottom: 2.5px solid #ED1C24;
}

.inactive-menu-item {
    color: rgb(107 114 128);
    border-bottom: 2.5px solid transparent;
}

.caret {
    display: none;
}


/* Common classes */

 .main-container {
    width: 100%;
    height: calc(100vh - 105px);
 }

.cursor-pointer{
    cursor: pointer;
}

/* Text color variation */

.text-primary{
    color: #ED1C24 !important;
}

.text-grey-100{
    color: #2c2c2c;
}

.text-grey-200{
    color:  #667085;
}

.text-grey-300{
    color: #98A2B3;
}

.text-grey-400{
    color: #D0D5DD;   
}


/* Font size variation */

.text-12{
    font-size: 12px;
}

.text-14{
    font-size: 14px;
}

.text-16{
    font-size: 16px;
}

.text-18{
    font-size: 18px;
}

.text-20{
    font-size: 20px;
}

.text-24{
    font-size: 24px;
}

/* Font weight variation */

.text-bold-400 {
    font-weight: 400;
}

.text-bold-500{
    font-weight: 500;
}

.text-bold-600{
    font-weight: 600;
}

.text-bold-700{
    font-weight: 700;
}

.text-bold-800{
    font-weight: 800;
}

.text-bold-900{
    font-weight: 900;
}


/* ------------------------------------------------------------------------------------------------- */


.bg-primary{
    background-color: #ED1C24 !important;
    border: none;
    outline: none;
}

.btn-primary{
    color: white;
    outline: none;
    border: none;
    background-color: #ED1C24 !important;
}

.btn-primary:hover{
    background-color: #bd070d !important;
}

.btn-overlay-red{
    background-color: #ED1C24 !important;
    outline: none;
    border: none;
}


.hide-arrow-button::-webkit-outer-spin-button,
.hide-arrow-button::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
.hide-arrow-button[type=number] {
  -moz-appearance: textfield;
}

::placeholder {
    font-size: 14px;
    color: #98A2B3;
}